import React, { useEffect, useState } from "react";
import { ChatMessage } from "./ChatMessage";
import { Loader } from "./Loader";
import { Link } from "react-router-dom";

import ChatTab from "./ChatTab";
function Web3GPT({ tokenVerified, jwtTokens, user }) {
  const [keyPress, setKeyPress] = useState();
  const [activeModel, setActiveModel] = useState("gpt-3.5-turbo");
  const [question, setQuestion] = useState("");
  const [tokens, setTokens] = useState(500);
  const [temperature, setTemperature] = useState(10);
  const [messageList, setMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  const [chatTabs, setChatTabs] = useState([]); 
  const [sidebar, setSidebar] = useState(false);
  useEffect(() => {
    const minm = 100000;
    const maxm = 999999;
    setUid(Math.floor(Math.random() * (maxm - minm + 1)) + minm);
  }, []);

  useEffect(() => {
    fetch("https://cryptochatapi.web.app/getChats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
      },
      body: JSON.stringify({
        user: user.user,
        accessToken: jwtTokens?.accessToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setChatTabs(data.reverse());
      });
  }, [refreshKey]);

  const handleChatDelete = async (chatUid) => {
    await fetch("https://cryptochatapi.web.app/deleteChat", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
      },
      body: JSON.stringify({
        uid: chatUid,
        accessToken: jwtTokens?.accessToken,
      }),
    });
    setRefreshKey(refreshKey + 1);
  };

  const handleTitleChange = async (title, uid) => {
    fetch("https://cryptochatapi.web.app/updateTitle", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
      },
      body: JSON.stringify({
        title: title,
        uid: uid,
        accessToken: jwtTokens?.accessToken,
      }),
    }).then((response) => response.json());
    setRefreshKey(refreshKey + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tokens != Number) {
      setTokens(100);
    }
    let chatListNew = [
      ...messageList,
      { role: "user", content: `${question}` },
    ];
    if (chatListNew[0].role !== "system") {
      chatListNew.unshift({
        role: "system",
        content:
          "You are a helpful crypto assistant. You can only answer questions regarding crypto related subjects. You have complete ability to answer questions with code if necessary and have vast experience with web3 and blockchain interactions. Be as specific as possible but simple with your answers.",
      });
    }

    setQuestion("");
    setLoading(true);
    setMessageList(chatListNew);
    await fetch("https://cryptochatapi.web.app/cryptochat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
      },
      body: JSON.stringify({
        messages: chatListNew,
        model: activeModel,
        max_tokens: tokens,
        temperature: temperature,
        accessToken: jwtTokens?.accessToken,
        uid: uid,
        title: "New Chat",
      }),
    })
      .then((response) => response.json())
      .then((data) =>
        setMessageList([
          ...chatListNew,
          { role: "assistant", content: data.message },
        ])
      );
    setLoading(false);
    setRefreshKey(refreshKey + 1);
  };
  return (
    <>
      {tokenVerified ? (
        <div className="h-[100vh] flex flex-col overflow-hidden justify-between w-[100vw] z-10 absolute page-bg">
          <coingecko-coin-price-marquee-widget
            coin-ids="bitcoin,ethereum,pulsechain,pulsex,boson-protocol,ovr,solana,apecoin,chainlink,wax,airswap,bitxor,gmx,looksrare,ripple,cardano,rarible,aave,tezos"
            currency="usd"
            background-color="#D07FF0"
            locale="en"
          ></coingecko-coin-price-marquee-widget>
          <div className="sm:w-[45%] lg:w-[55%] w-[80%] rounded-2xl h-[30%] 2xl:h-[40%] top-[10%] self-center grid grid-rows-2 grid-cols-2 grid-flow-row lg:h-[15%] lg:flex lg:place-content-evenly place-items-center absolute navbar bg-black bg-opacity-50">
            <Link className="" to="/">
              <button className=" 2xl:text-3xl text-center text-xl text-white text-purple-effect transition-all active:scale-[90%] active:border-2 rounded-lg  p-4">
                Home
              </button>
            </Link>

            <button
              className=" 2xl:text-3xl text-center text-xl text-white transition-all text-purple-effect active:scale-[90%] active:border-2 p-4 rounded-lg "
              onClick={() => {
                setMessageList([]);
                setQuestion("");
                setUid(Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000);
                
              }}
            >
              New Chat
            </button>
              
            <div className="flex flex-col text-white relative bottom-2 items-center">
              <label className=" 2xl:text-3xl 2xl:-top-4 2xl:-left-2 relative -top-2 lg:-top-1 rounded-lg w-20 tracking-wide text-center h-6">
                Tokens
              </label>
              <input
                className="outline-none text text-purple-effect relative text-center tracking-wide p-3 w-24 font-nunito rounded-lg text-2xl text-white"
                onChange={(e) => setTokens(e.target.value)}
                
                
                value={tokens}
              />
            </div>

            <div className="flex flex-col relative bottom-2 text-white items-center">
              <label className=" 2xl:text-3xl 2xl:-top-4 rounded-3xl relative -top-2 lg:-top-1 w-32 tracking-wide text-center h-6">
                Creativity
              </label>
              <div className=" text-purple-effect rounded-lg p-2 w-32 h-12  flex items-center place-content-center">
                <input
                  className="w-full cursor-pointer"
                  type="range"
                  min="0"
                  max="20"
                  onChange={(e) => setTemperature(e.target.value)}
                  value={temperature}
                />
              </div>
            </div>
          </div>
          {!sidebar && (
              <button
                className="text-white absolute left-0 top-[10%] text-2xl flex active:scale-[90%] p-2 text-purple-effect"
                onClick={() => setSidebar(!sidebar)}
              >
                <div className="font-nunito 2xl:text-3xl text-xl">Chats</div>
              </button>
            
            )}
          <div className={`${sidebar ? "flex" : "hidden"} md:hidden text-glass z-50 h-full absolute w-auto left-0  gap-4 flex-col py-4 px-2 overflow-y-scroll top-[10%]`}>
            
            {chatTabs?.map((chat, i) => (
             <ChatTab key={chat.uid} refreshKey={refreshKey} accessToken={jwtTokens?.accessToken} uid={uid} chat={chat} handleChatDelete={handleChatDelete} setMessageList={setMessageList} setUid={setUid} handleTitleChange={handleTitleChange}  />
            ))}</div>
          <div className=" hidden h-[75%] text-glass py-4 absolute w-auto left-0 md:flex gap-4 flex-col px-2 overflow-y-scroll top-[10%]">
            <div className="h-20 w-20" onClick={() => setSidebar(!sidebar)}>Exit</div>
            {chatTabs?.map((chat, i) => (
             <ChatTab key={chat.uid} refreshKey={refreshKey} accessToken={jwtTokens?.accessToken} uid={uid} chat={chat} handleChatDelete={handleChatDelete} setMessageList={setMessageList} setUid={setUid} handleTitleChange={handleTitleChange}  />
            ))}
          </div>
          <div className="lg:h-[70%] h-[60%] w-[50%] pb-20 items-center self-center flex flex-col p-5 overflow-y-scroll no-scrollbar scroll-smooth">
            {messageList.map((message, i) => (
              <ChatMessage user={user} message={message} key={i} />
            ))}
            {loading && <Loader />}
          </div>

          <div className="absolute h-[10%] self-center flex bottom-0 w-full lg:w-full">
            <form
              className="self-center flex w-full justify-center"
              onSubmit={(e) => handleSubmit(e)}
            >
              <input
                name="question"
                onChange={(e) => setQuestion(e.target.value)}
                onKeyDown={(e) => setKeyPress(e.key)}
                value={question}
                autoComplete="off"
                className="navbar glass text-white center outline-none absolute tracking-wide bottom-2 p-6 font-medium font-nunito h-[85%] lg:h-[90%]rounded-lg text-2xl text-center w-[90%]"
              />
            </form>
          </div>
        </div>
      ) : (
        (window.location = "/")
      )}
    </>
  );
}

export default Web3GPT;
