import React, { useEffect, useState } from "react";
import { FaRegTrashCan } from "react-icons/fa6";

function ChatTab({
  chat,
  setUid,
  uid,
  setMessageList,
  handleTitleChange,
  handleChatDelete,
  accessToken
}) {
  const [title, setTitle] = useState(chat.title);
  const [isFocused, setIsFocused] = useState(true);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleTitleChange(title, chat.uid);
    }
  };
  
  const buttonWidth = `150px`;
  const maxInputWidth = "200px";
  const buttonStyle = {
    width: isFocused ? buttonWidth : maxInputWidth,
    maxWidth: maxInputWidth,
  };
  useEffect(() => {
    fetch("https://cryptochatapi.web.app/getChat", {
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "true",
        },
        body: JSON.stringify({uid: uid, accessToken: accessToken})
     }).then((response) => response.json()).then((data) => {setTitle(data.title)})
  },[])

  return (
    <button
      className={`${isFocused ? buttonWidth: maxInputWidth}  text-white 2xl:text-3xl 2xl:w-[250px] text-xl flex active:scale-[90%] p-2 text-purple-effect`}
      
      onClick={() => {
        setMessageList(chat.messages);
        setUid(chat.uid);
      }}
    >
      <input
        className={`bg-transparent w-[150px] outline-none ${
          isFocused ? "truncate" : ""
        }`}
        value={title}
        type="text"
        onFocus={() => setIsFocused(false)}
        onBlur={() => setIsFocused(true)}
        onChange={(e) => setTitle(e.target.value)}
        onKeyDown={handleKeyPress}
      />
      <div
        className="self-center flex justify-center"
        onClick={() =>handleChatDelete(chat.uid)}
      >
        <FaRegTrashCan  className="self-center" />
      </div>
    </button>
  );
}

export default ChatTab;
