import React from "react";
import "../App.css";

import Aggregator from "./Aggregator.jsx";
import DesktopButtons from "./DesktopButtons.jsx";
import MobileButtons from "./MobileButtons.jsx";
function Home({
  jwtTokens,
  setJwtTokens,
  user,
  setUser,
  expired,
  setExpired,
  tokenVerified,
  setTokenVerified,
}) {
  return (
    <div className="h-[100vh] no-scrollbar  w-[100vw] flex flex-col page-bg">
      <div className="z-50">
        <DesktopButtons
          tokenVerified={tokenVerified}
          setJwtTokens={setJwtTokens}
          setUser={setUser}
          user={user}
          jwtTokens={jwtTokens}
          setExpired={setExpired}
          expired={expired}
          setTokenVerified={setTokenVerified}
        />
        <MobileButtons
          tokenVerified={tokenVerified}
          setJwtTokens={setJwtTokens}
          setUser={setUser}
          user={user}
          jwtTokens={jwtTokens}
          setExpired={setExpired}
          expired={expired}
          setTokenVerified={setTokenVerified}
        />
        </div>
        <div className="z-1">
        <Aggregator />
        </div>
      
    </div>
  );
}

export default Home;
