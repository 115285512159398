import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  TwitterAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
} from "firebase/auth";
import { firebaseConfig } from "../firebase.js";
import google from "../assets/google.png";
import github from "../assets/github.png";
import twitter from "../assets/twitter.jpeg";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
export const LoginModal = ({
  show,
  setShow,
  setSwitchShow,
  switchShow,
  user,
  setUser,
  setTokens,
}) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const handleModalClose = (e) => {
    if (e.target.id === "closer") {
      setShow(!show);
    }
  };

  // const handleFacebookLogin = async () => {
  //   let uid = "";
  //   const provider = new FacebookAuthProvider();
  //   await firebase
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then((response) => console.log(response))
  //     .catch((err) => console.log(err));
  //     if (uid) {
  //       await fetch("http://localhost:3012/login", {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({ user: uid }),
  //       })
  //         .then((response) => response.json())
  //         .then((data) => {
  //           setUser(data[0]);
  //           console.log(data[0], data[1]);
  //           setTokens(data[1]);
  //           setShow(!show);
  //         });
  //     }
  // };
  const handleGithubLogin = async () => {
    let uid = "";
    setError();
    const provider = new GithubAuthProvider();
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then((response) => (uid = response.additionalUserInfo.username))
      .catch((err) => setError(err));
    if (uid) {
      await fetch("https://cryptochatapi.web.app/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user: uid }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUser(data[0]);
          setTokens(data[1]);
          setShow(!show);
        });
    }
  };

  const handleTwitterLogin = async () => {
    setError();
    let uid = "";
    const provider = new TwitterAuthProvider();
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then((response) => (uid = response.additionalUserInfo.username))
      .catch((err) => console.log(err));
    if (uid) {
      await fetch("https://cryptochatapi.web.app/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user: uid }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUser(data[0]);
          setTokens(data[1]);
          setShow(!show);
        })
        .catch((err) => setError(err));
    }
  };
  const handleGmailLogin = async () => {
    setError();
    let uid = "";
    await firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((response) => {
        uid = response.user.email;
      })
      .catch((err) => console.log(err));
    uid = uid.replace(/\//g, "-");
    if (uid) {
      await fetch("https://cryptochatapi.web.app/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user: uid }),
      })
        .then((response) => response.json())
        .then((data) => {
          setUser(data[0]);
          setTokens(data[1]);
          setShow(!show);
        })
        .catch((err) => setError(err));
    }
  };

  return (
    <div
      onClick={handleModalClose}
      id="closer"
      className="z-100 absolute backdrop-blur top-0 bottom-0 left-0 flex items-center place-content-center right-0 "
    >
      <div className="z-100 modal glass w-[80%] sm:w-[22rem] z-50 h-[75%] flex flex-col place-content-evenly p-6 text-white rounded-2xl absolute">
        <button
          className="effect-button  flex gap-2 font-bold self-center p-2"
          onClick={handleGmailLogin}
        >
          {" "}
          <p className="self-center">Login with Google</p>{" "}
          <img className="rounded-full h-10 w-10" src={google} />
        </button>
        <button
          className="effect-button flex gap-2 font-bold self-center p-2"
          onClick={handleTwitterLogin}
        >
          {" "}
          <p className="self-center">Login with Twitter</p>{" "}
          <img className=" rounded-full h-10 w-10" src={twitter} />
        </button>
        {/* <button className="effect-button w-24 font-bold self-center p-2" onClick={handleFacebookLogin}>Login with Facebook</button> */}
        <button
          className="effect-button flex gap-2 font-bold self-center p-2"
          onClick={handleGithubLogin}
        >
          <p className="self-center">Login with Github</p>
          <img className="rounded-full h-10 w-10" src={github} />
        </button>

        {error && (
          <p className="text-xl font-bold text-red-400">
            {error.message.slice(9)}
          </p>
        )}
        <button
          id="closer"
          className="text-purple-effect place-self-center text-center text-xl text-white transition-all active:scale-[90%] active:border-2 p-1 rounded-full  w-32 h-16"
          onClick={handleModalClose}
        >
          Close Login
        </button>
      </div>
    </div>
  );
};
