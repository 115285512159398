import React, { useState } from "react";
import Pulsechain from "../assets/pulsechain.jpg";
import arrow from "../assets/arrow.png";
import ledgerad from "../assets/ledgerad.jpeg";
import ledgerad2 from "../assets/ledgerad2.jpeg";
import trezorad from "../assets/trezorad.jpg";
import trezorad2 from "../assets/trezorad2.jpg";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
function Aggregator() {
  const [timeDropdown, setTimeDropdown] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const locale = localStorage.getItem("locale");
  const timezone = localStorage.getItem("timezone");
  const locales = [
    "en",
    "in",
    "de_DE",
    "fr",
    "es",
    "it",
    "pl",
    "sv_SE",
    "tr",
    "ru",
    "br",
    "id",
    "ms_MY",
    "th_TH",
    "vi_VN",
    "ja",
    "kr",
    "zh_CN",
    " zh_TW",
    "ar_AE",
    "he_IL",
    "hu_HU",
    "fa_IR",
  ];
  const timezones = [
    "ETC/UTC",
    "America/New_York",
    "America/Los_Angeles",
    "America/Chicago",
    "America/Juneau",
    "America/Denver",
    "America/Phoenix",
    "America/Anchorage",
    "Pacific/Honolulu",
    "America/Bogota",
    "America/Mexico_City",
    "America/San_Salvador",
    "America/Lima",
    "America/Toronto",
    "America/Caracas",
    "America/Buenos_Aires",
    "America/Santiago",
    "America/Sao_Paulo",
    "Europe/Dublin",
    "Europe/Lisbon",
    "Europe/London",
    "Europe/Reykjavik",
    "Europe/Amsterdam",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Rome",
    "Europe/Paris",
    "Africa/Cairo",
    "Europe/Bucharest",
    "Europe/Istanbul",
    "Asia/Kuwait",
    "Europe/Moscow",
    "Asia/Tehran",
    "Asia/Dubai",
    "Asia/Karachi",
    "Asia/Colombo",
    "Asia/Kathmandu",
    "Asia/Almaty",
    "Asia/Yangon",
    "Asia/Bangkok",
    "Asia/Hong Kong",
    "Asia/Seoul",
    "Asia/Tokyo",
    "Australia/Brisbane",
    "Australia/Adelaide",
    "Australia/Sydney",
    "Pacific/Norfolk",
    "Pacific/Auckland",
  ];
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };
  const handleDropdownClose = (e) => {
    localStorage.setItem("locale", e);
    setDropdown(false);
    window.location.reload();
  };
  const handleTimeDropdown = () => {
    setTimeDropdown(!timeDropdown);
  };
  const handleTimeDropdownClose = (e) => {
    localStorage.setItem("timezone", e);
    setTimeDropdown(false);
    window.location.reload();
  };
  return (
    <>
      <div className="hidden  xl:flex overflow-hidden absolute  z-1">
      <div className="flex flex-col gap-2 h-full w-full">
          <div className="w-full h-[65vh] overflow-hidden">
            <AdvancedRealTimeChart
              theme="dark"
              symbol="ETHBTC"
              timezone={timezone}
              locale={locale}
              interval="240"
              range="1M"
              
             
              autosize
            />
          </div>
          <div className="flex justify-around my-2 gap-2">
            
              <div className="flex gap-2">
                {" "}
                <p className="text-purple-effect rounded-lg  text-white font-bold h-10  p-2">
                  Language
                </p>
                <button
                  onClick={handleDropdown}
                  className="text-blue-effect text-center rounded-lg text-white h-8 mt-1  px-2"
                >
                  {localStorage.getItem("locale")
                    ? localStorage.getItem("locale")
                    : "en"}
                </button>
        

              <div
                id={`${dropdown ? "opendown" : "closeup"}`}
                className="h-16 px-4 self-center rounded-lg text-purple-effect overflow-y-scroll no-scrollbar  flex flex-col"
              >
                {" "}
                {locales.map((l) => {
                  const locale = l;
                  return (
                    <button
                      onClick={(e) => handleDropdownClose(locale)}
                      className="self-center text-white text-blue-effect my-2 px-4"
                    >
                      {l}{" "}
                    </button>
                  );
                })}{" "}
              </div>
            </div>
            
              
              <div className="flex gap-2">
              <p className="rounded-lg text-white font-bold text-purple-effect h-10 p-2">
                  Timezone
                </p>
                <button
                  onClick={handleTimeDropdown}
                  className="text-blue-effect rounded-lg text-center text-white h-10 px-2 "
                >
                  {localStorage.getItem("timezone")
                    ? localStorage.getItem("timezone")
                    : "ETC/UTC"}
                </button>

                <div
                  id={`${timeDropdown ? "opendown" : "closeup"}`}
                  className={` px-4  text-purple-effect overflow-y-scroll no-scrollbar rounded-lg flex flex-col `}
                >
                  {" "}
                  {timezones.map((t) => {
                    const timezone = t;
                    return (
                      <button
                        onClick={(e) => handleTimeDropdownClose(timezone)}
                        className="self-center text-white text-blue-effect my-2 px-4"
                      >
                        {timezone}{" "}
                      </button>
                    );
                  })}{" "}
                </div>
             
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[35%] ">
          <iframe
            id="iframe-widget"
            src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=0.3&amountFiat=1500&backgroundColor=2B2B35&darkMode=true&from=eth&fromFiat=eur&horizontal=true&isFiat&lang=en-US&link_id=9e9d33b20f2355&locales=true&logo=true&primaryColor=07ee03&to=pls&toFiat=eth&toTheMoon=true"
            className="h-[50vh] w-[30vw] flex flex-col self-center border-none"
          ></iframe>{" "}
          <p className=" text-purple-effect self-center text-white mx-4 px-2 font-medium tracking-wide">
            The Pulsechain bridge offers the most efficient way to move in and
            out of the PulseChain ecosystem.
          </p>
          <a
            className=" flex justify-around self-center w-full my-2"
            href="https://bridge.mypinata.cloud/ipfs/bafybeiagreiatsnjvoriublqmv3bz4atiu33pecffdiyzponjbvzf3klwm/#/bridge"
            target="_blank"
            rel="noreferrer"
          >
            <div className="text-purple-effect hover:scale-95 hover:border-2 flex justify-around self-center text-center text-xl text-white transition-all active:scale-[90%] active:border-2 p-1 rounded-full w-64 h-12">
              <p className="self-center font-bold">Pulsechain Bridge</p>{" "}
              <img src={arrow} className="self-center h-12 mt-0.5 w-12" />
            </div>
            <img
              className="self-center hover:scale-95 hover:border-2 rounded-xl h-28 w-28"
              src={Pulsechain}
            />
          </a>
        </div>
      </div>
      <div className="xl:hidden sm:h-[70vh] h-[55vh] flex z-1 flex-col justify-between overflow-y-scroll w-full p-4">
        <div className=" self-center max-sm:w-[100%]  flex h-[50vh]">
          <iframe
            id="iframe-widget"
            src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=0.3&amountFiat=1500&backgroundColor=2B2B35&darkMode=true&from=eth&fromFiat=eur&horizontal=true&isFiat&lang=en-US&link_id=9e9d33b20f2355&locales=true&logo=true&primaryColor=07ee03&to=pls&toFiat=eth&toTheMoon=true"
            className="h-[400px] w-[700px] border-none"
          ></iframe>
        </div>
        <div className="flex flex-col w-full mt-8 no-scrollbar h-[590px]">
            <div className="flex self-center gap-2 py-2">
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  {" "}
                  <p className="text-purple-effect rounded-lg self-center text-white font-bold  p-2">
                    Language
                  </p>
                  <button
                    onClick={handleDropdown}
                    className="text-blue-effect text-center rounded-lg text-white h-8 self-center px-2"
                  >
                    {localStorage.getItem("locale")
                      ? localStorage.getItem("locale")
                      : "en"}
                  </button>
                </div>

                <div
                  id={`${dropdown ? "opendown" : "closeup"}`}
                  className="h-16 px-4 self-center mt-2 rounded-lg text-purple-effect overflow-y-scroll no-scrollbar  flex flex-col"
                >
                  {" "}
                  {locales.map((l) => {
                    const locale = l;
                    return (
                      <button
                        onClick={(e) => handleDropdownClose(locale)}
                        className="self-center text-white text-blue-effect my-2 px-4"
                      >
                        {l}{" "}
                      </button>
                    );
                  })}{" "}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <p className="self-center rounded-lg text-white font-bold text-purple-effect p-2">
                    Timezone
                  </p>
                  <button
                    onClick={handleTimeDropdown}
                    className="text-blue-effect rounded-lg text-center text-white h-10 px-2 "
                  >
                    {localStorage.getItem("timezone")
                      ? localStorage.getItem("timezone")
                      : "ETC/UTC"}
                  </button>
                </div>

                <div
                  id={`${timeDropdown ? "opendown" : "closeup"}`}
                  className={` self-center mt-2  text-purple-effect overflow-y-scroll no-scrollbar rounded-lg flex flex-col `}
                >
                  {" "}
                  {timezones.map((t) => {
                    const timezone = t;
                    return (
                      <button
                        onClick={(e) => handleTimeDropdownClose(timezone)}
                        className="self-center px-2 text-white text-blue-effect my-2"
                      >
                        {timezone}{" "}
                      </button>
                    );
                  })}{" "}
                </div>
              </div>
            </div>
            <div className="h-full w-full overflow-y-hidden">
              <AdvancedRealTimeChart
                theme="dark"
                symbol="ETHBTC"
                timezone={timezone}
                locale={locale}
                interval="240"
                range="1M"
                className="self-center"
                height={500}
                
                width={window.innerWidth - 50}
                adaptive_logo={true}
              />
      </div>
          </div>
        <div className="flex w-full justify-around mb-4">
          <a
            href="https://trezor.io/twin-bundle-t?transaction_id=102f808c6434aa5309a0b8e50e0bf2&offer_id=154&affiliate_id=33988"
            target="_blank"
            rel="noreferrer"
            className="sm:flex hidden"
          >
            <img
              src={trezorad2}
              className="rounded-xl h-[24rem] sm:h-[28rem] w-32 sm:w-64"
            />
          </a>
          <a
            href="https://shop.ledger.com/pages/ledger-nano-s-plus/?r=864f0adf98ae"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={ledgerad2}
              className="rounded-xl h-[24rem] sm:h-[28rem] w-52"
            />
          </a>
        </div>
        <p className=" text-purple-effect text-white px-3 py-2 mb-2 text-xl tracking-wide">
         The Pulsechain
          bridge offers the most efficient way to move in and out of the
          PulseChain ecosystem.
        </p>

        <a
          href="https://bridge.mypinata.cloud/ipfs/bafybeiagreiatsnjvoriublqmv3bz4atiu33pecffdiyzponjbvzf3klwm/#/bridge"
          target="_blank"
          rel="noreferrer"
          className="flex justify-center gap-4 w-full self-center"
        >
          <div className="text-purple-effect hover:scale-95 flex justify-center gap-1 self-center text-center text-l text-white transition-all active:scale-[90%] active:border-2 p-1 rounded-full">
            <p className="self-center text-l font-bold  hover:scale-95">
              Pulsechain Bridge
            </p>{" "}
            <img src={arrow} className="self-center h-10 mt-0.5 w-10" />
          </div>
          <img
            className="self-center hover:scale-95 hover:border-2 rounded-xl h-32 w-32"
            src={Pulsechain}
          />
        </a>
      </div>
    </>
  );
}

export default Aggregator;
