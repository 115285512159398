import React from "react";
import Agora from "../assets/AgoraDesk.png";
import MobileButtons from "./MobileButtons";
import DesktopButtons from "./DesktopButtons";
import appointment from "../assets/appointment.png";
import telegram from "../assets/telegram.png";
import consulting from "../assets/quantumlogo.jpg";
function PrivateFiat({
  jwtTokens,
  setJwtTokens,
  user,
  setUser,
  expired,
  setExpired,
  tokenVerified,
  setTokenVerified,
}) {
  return (
    <>
      <div className="h-[100vh] w-[100vw] z-10 flex flex-col absolute page-bg">
        {" "}
        <div className="flex flex-col place-content-between">
          <div className="z-50">
            <DesktopButtons
              tokenVerified={tokenVerified}
              setJwtTokens={setJwtTokens}
              setUser={setUser}
              user={user}
              jwtTokens={jwtTokens}
              setExpired={setExpired}
              expired={expired}
              setTokenVerified={setTokenVerified}
            />
            <MobileButtons
              tokenVerified={tokenVerified}
              setJwtTokens={setJwtTokens}
              setUser={setUser}
              user={user}
              jwtTokens={jwtTokens}
              setExpired={setExpired}
              expired={expired}
              setTokenVerified={setTokenVerified}
            />
          </div>
        </div>
        <div className="px-4">
          <div className="hidden xl:flex h-[80vh] overflow-y-scroll no-scrollbar overflow-x-hidden effect-purple justify-around rounded-xl w-full px-4">
            <div className="h-100% flex flex-col">
              <div className="z-1 translate-x-10 flex relative self-center align-center justify-center">
                <iframe
                  id=""
                  className="self-center"
                  allowtransparency="true"
                  frameborder="0"
                  hspace="0"
                  vspace="0"
                  marginheight="0"
                  marginwidth="0"
                  scrolling="no"
                  width="210"
                  height="130"
                  src="https://localmonero.co/priceWidget?currencyCode=USD&fallback=convert&rc=n6s2"
                ></iframe>
                <iframe
                  id=""
                  className=" self-center"
                  src="https://trocador.app/widget/?ref=adi7LNVRJ3"
                  width={450}
                  height={350}
                  scrolling="no"
                ></iframe>
                <a
                  id=""
                  href="https://agoradesk.com/?rc=n6s2"
                  target="_blank"
                  rel="noreferrer"
                  className="self-center  bg-[#ffffff] h-[40%] rounded-xl cursor-pointer"
                >
                  <img className=" w-80 h-32" src={Agora} />
                </a>
              </div>
              <p className="font-bold text-glass text-center text-white p-1.5 my-1 text-xl tracking-wide">
                Best Practices to optimize your trading experience on Local
                Monero and Agora Desk
              </p>
              <p className=" text-glass text-white p-4 my-1 text-xl tracking-wide">
                • Select Established Traders: Prioritize offers from traders who
                have established a credible reputation. Look for high scores and
                an extensive array of positive reviews spanning a considerable
                duration, including recent feedback. This step is crucial for
                gauging the reliability and trustworthiness of potential trading
                partners.
                <br></br>• Engage with Active Traders: For swift transactions,
                choose traders who are currently online. This approach typically
                results in receiving funds within minutes, thanks to the prompt
                response and transaction processing from active participants.
                <br></br>• Be Cautious with Inactive Traders: Steer clear of
                traders who have been inactive for extended periods, regardless
                of their past feedback. Inactivity can be a red flag in the
                dynamic environment of cryptocurrency trading.
                <br></br>• No ID or KYC Offers: To maintain privacy, select
                offers that explicitly state 'No ID' or 'No KYC.' This ensures
                your transaction adheres to the platform's principle of
                anonymity.
                <br></br>• Preferred Payment Methods: For secure and
                straightforward transactions, deal primarily with bank
                transfers, cash in post, or cash in person. Revolut is another
                safe option if you already have an account; it's widely used
                within the Local Monero community and facilitates international
                transactions.
                <br></br>• Avoid Certain Payment Providers: Exercise caution and
                avoid trade offers involving PayPal, Skrill, Neteller, and
                similar services. These platforms pose a higher risk of scams,
                as transactions can be reversed by the other party, even after
                finalization on Local Monero.
                <br></br>• Finalizing Transactions: Exercise due diligence
                before finalizing any trade. Never click 'Finalize' to release
                your Monero until you have verified the receipt of funds in your
                bank account, or, in the case of a cash trade, physically
                checked and counted the cash.
                <br></br>
                <br></br>
                By adhering to these best practices, you can navigate the Local
                Monero platform with greater security and confidence, ensuring a
                positive and successful trading experience.
                <br></br>
                <br></br>
                Despite the name, trades don't have to be Local, you can also
                choose cash in post for nationwide trades, or bank transfer
                which can be local, nationwide or even worldwide. Bank transfer
                trades are normally completed and in your bank within minutes.
                <br></br>
                <br></br>Your Monero stays in your Local Monero wallet, you keep
                custody of your assets throughout the whole process, no 3rd
                parties.
                <br></br>
                <br></br>Local Monero is the arbiter to ensure the assets are
                received safely by both parties. During a trade, your Monero
                stays locked in an escrow until you either click "Finalize" to
                confirm receipt of the payment or cancel, thus you have very
                high security guarantees.<br></br>
                <br></br>You receive funds from the other party, not Local
                Monero. In this way, the payment is not specific to crypto, so
                any restrictions your jurisdiction may have on crypto related
                transactions are bypassed and your personal details are kept
                private. <br></br>
                <br></br>Use Our{" "}
                <a
                  className="font-bold underline text-emerald-500"
                  href="https://web3stormlive.web.app/"
                >
                  Dex Aggregator
                </a>{" "}
                to privately exchange and swap to Monero (XMR), directly into
                your LocalMonero wallet address. You can do this in your
                built-in LocalMonero wallet which lives inside the exchange and
                acts like a secure online bank account for holding Monero (XMR).
                The best part? You can make this exchange in just one simple
                step, directly into your Local Monero account.
                <br></br>
                <br></br>
                If you prefer to buy Bitcoin (BTC) peer2peer with fiat, your
                LocalMonero login details also work with their partner website,
                AgoraDesk, which is like a clone of LocalMonero & allows you to
                use your LocalMonero login details to access AgoraDesk too! It
                works in the same way and shows both your Bitcoin(BTC) and
                Monero (XMR) balances from LocalMonero. If you want, you can
                also start directly with AgoraDesk. You don't need to give any
                personal information or undergo any detailed identity checks
                (known as KYC) to create an account in{" "}
                <a
                  className="font-bold underline text-emerald-500"
                  href="https://agoradesk.com/?rc=n6s2"
                >
                  Agora Desk
                </a>
                . Just pick a username, set a password, and you're good to go.
                And guess what? Once you have an account with AgoraDesk, you can
                use those same details to log in to LocalMonero as well!
              </p>
              <div className="flex flex-col">
                <p className="text-white text-glass text-xl p-4">
                  Dive into our Telegram community here and become part of an
                  elite group passionate about navigating the complexities of
                  the digital era.
                  <br></br> Why You Can't Miss Out: 💡 Exclusive Insights: Get
                  first-hand access to cutting-edge crypto analysis and
                  strategies that you won't find anywhere else
                  <br></br> 🤝 Community Support: Be part of a vibrant community
                  where members share their experiences, tips, and help each
                  other grow. <br></br>🙊 Exclusive Content: Get your hands on
                  meticulously curated blogs and articles. <br></br>📊 Real-time
                  Updates: Stay ahead with real-time market updates, ensuring
                  you're always in the loop.<br></br> 🚀 Growth Opportunities:
                  Whether you're a beginner or an expert, our community is
                  designed to fuel your growth in the crypto world.
                  <p className="font-bold text-center">👇 Join Us Now 🌐✨</p>
                </p>
                <div className="self-center w-full flex flex-col sm:flex-row gap-5 sm:gap-0 justify-center my-2">
                  <a
                    href="https://t.me/oaksoftdigital"
                    className="self-center sm:mr-10"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className=" self-center h-40 w-40 rounded-full hover:border-4"
                      src={telegram}
                    />
                  </a>

                  <a
                    href="https://calendly.com/etherstream"
                    className="self-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className=" self-center h-40 w-40 rounded-full hover:border-4"
                      src={consulting}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:hidden effect-purple mt-2 flex flex-col overflow-y-scroll no-scrollbar overflow-x-hidden h-[55vh] md:h-[75vh] py-2 rounded-xl px-4">
            <div className="flex flex-col relative justify-center w-full">
              <div className="flex flex-col self-center justify-around h-[35] w-full">
                <iframe
                  id=""
                  className="self-center "
                  allowtransparency="true"
                  frameborder="0"
                  hspace="0"
                  vspace="0"
                  marginheight="0"
                  marginwidth="0"
                  scrolling="no"
                  width="210"
                  height="130"
                  src="https://localmonero.co/priceWidget?currencyCode=USD&fallback=convert&rc=n6s2"
                ></iframe>
                <iframe
                  id=""
                  className="self-center "
                  src="https://trocador.app/widget/?ref=adi7LNVRJ3"
                  width={400}
                  height={350}
                  scrolling="no"
                ></iframe>
                <a
                  id=""
                  href="https://agoradesk.com/?rc=n6s2"
                  target="_blank"
                  rel="noreferrer"
                  className="self-center  my-4 bg-[#ffffff] rounded-xl cursor-pointer"
                >
                  <img className=" w-80 h-full" src={Agora} />
                </a>
              </div>
            </div>
            <p className="font-bold text-center text-glass text-white p-1.5 my-1 text-xl tracking-wide">
              Best Practices to optimize your trading experience on Local Monero
              and Agora Desk
            </p>
            <p className="text-glass text-white p-4 my-1 text-xl tracking-wide">
              • Select Established Traders: Prioritize offers from traders who
              have established a credible reputation. Look for high scores and
              an extensive array of positive reviews spanning a considerable
              duration, including recent feedback. This step is crucial for
              gauging the reliability and trustworthiness of potential trading
              partners.
              <br></br>• Engage with Active Traders: For swift transactions,
              choose traders who are currently online. This approach typically
              results in receiving funds within minutes, thanks to the prompt
              response and transaction processing from active participants.
              <br></br>• Be Cautious with Inactive Traders: Steer clear of
              traders who have been inactive for extended periods, regardless of
              their past feedback. Inactivity can be a red flag in the dynamic
              environment of cryptocurrency trading.
              <br></br>• No ID or KYC Offers: To maintain privacy, select offers
              that explicitly state 'No ID' or 'No KYC.' This ensures your
              transaction adheres to the platform's principle of anonymity.
              <br></br>• Preferred Payment Methods: For secure and
              straightforward transactions, deal primarily with bank transfers,
              cash in post, or cash in person. Revolut is another safe option if
              you already have an account; it's widely used within the Local
              Monero community and facilitates international transactions.
              <br></br>• Avoid Certain Payment Providers: Exercise caution and
              avoid trade offers involving PayPal, Skrill, Neteller, and similar
              services. These platforms pose a higher risk of scams, as
              transactions can be reversed by the other party, even after
              finalization on Local Monero.
              <br></br>• Finalizing Transactions: Exercise due diligence before
              finalizing any trade. Never click 'Finalize' to release your
              Monero until you have verified the receipt of funds in your bank
              account, or, in the case of a cash trade, physically checked and
              counted the cash.
              <br></br>
              <br></br>
              By adhering to these best practices, you can navigate the Local
              Monero platform with greater security and confidence, ensuring a
              positive and successful trading experience.
              <br></br>
              <br></br>
              Despite the name, trades don't have to be Local, you can also
              choose cash in post for nationwide trades, or bank transfer which
              can be local, nationwide or even worldwide. Bank transfer trades
              are normally completed and in your bank within minutes.<br></br>
              <br></br>Your Monero stays in your Local Monero wallet, you keep
              custody of your assets throughout the whole process, no 3rd
              parties.
              <br></br>
              <br></br>Local Monero is the arbiter to ensure the assets are
              received safely by both parties. During a trade, your Monero stays
              locked in an escrow until you either click "Finalize" to confirm
              receipt of the payment or cancel, thus you have very high security
              guarantees.<br></br>
              <br></br>You receive funds from the other party, not Local Monero.
              In this way, the payment is not specific to crypto, so any
              restrictions your jurisdiction may have on crypto related
              transactions are bypassed and your personal details are kept
              private. <br></br>
              <br></br>Use Our{" "}
              <a
                className="font-bold underline text-emerald-500"
                href="https://web3stormlive.web.app/"
              >
                Dex Aggregator
              </a>{" "}
              to privately exchange and swap to Monero (XMR), directly into your
              LocalMonero wallet address. You can do this in your built-in
              LocalMonero wallet which lives inside the exchange and acts like a
              secure online bank account for holding Monero (XMR). The best
              part? You can make this exchange in just one simple step, directly
              into your Local Monero account.
              <br></br>
              <br></br>
              If you prefer to buy Bitcoin (BTC) peer2peer with fiat, your
              LocalMonero login details also work with their partner website,
              AgoraDesk, which is like a clone of LocalMonero & allows you to
              use your LocalMonero login details to access AgoraDesk too! It
              works in the same way and shows both your Bitcoin(BTC) and Monero
              (XMR) balances from LocalMonero. If you want, you can also start
              directly with AgoraDesk. You don't need to give any personal
              information or undergo any detailed identity checks (known as KYC)
              to create an account in{" "}
              <a
                className="font-bold underline text-emerald-500"
                href="https://agoradesk.com/?rc=n6s2"
              >
                Agora Desk
              </a>
              . Just pick a username, set a password, and you're good to go. And
              guess what? Once you have an account with AgoraDesk, you can use
              those same details to log in to LocalMonero as well!
            </p>
            <div className="flex flex-col">
                <p className="text-white text-glass text-xl p-4">
                  Dive into our Telegram community here and become part of an
                  elite group passionate about navigating the complexities of
                  the digital era.
                  <br></br> Why You Can't Miss Out: 💡 Exclusive Insights: Get
                  first-hand access to cutting-edge crypto analysis and
                  strategies that you won't find anywhere else
                  <br></br> 🤝 Community Support: Be part of a vibrant community
                  where members share their experiences, tips, and help each
                  other grow. <br></br>🙊 Exclusive Content: Get your hands on
                  meticulously curated blogs and articles. <br></br>📊 Real-time
                  Updates: Stay ahead with real-time market updates, ensuring
                  you're always in the loop.<br></br> 🚀 Growth Opportunities:
                  Whether you're a beginner or an expert, our community is
                  designed to fuel your growth in the crypto world.
                  <p className="font-bold text-center">👇 Join Us Now 🌐✨</p>
                </p>
                <div className="self-center w-full flex flex-col sm:flex-row gap-5 sm:gap-0 justify-center my-2">
                  <a
                    href="https://t.me/oaksoftdigital"
                    className="self-center sm:mr-10"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className=" self-center h-40 w-40 rounded-full hover:border-4"
                      src={telegram}
                    />
                  </a>

                  <a
                    href="https://calendly.com/etherstream"
                    className="self-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className=" self-center h-40 w-40 rounded-full hover:border-4"
                      src={consulting}
                    />
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivateFiat;
