import Home from "./components/Home";
import About from "./components/About";
import PrivateFiat from "./components/PrivateFiat";
import EVM from "./components/EVM";
import CrossChain from "./components/CrossChain";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { useState, useEffect } from "react";
import Web3GPT from "./components/Web3GPT";
function App() {
  const [tokenVerified, setTokenVerified] = useState(false);
  const [expired, setExpired] = useState(false);
  const [jwtTokens, setJwtTokens] = useState();
  const [user, setUser] = useState();
  useEffect(() => {
    fetch("https://cryptochatapi.web.app/getNewToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
      },
      body: JSON.stringify({
        refreshToken: jwtTokens?.refreshToken,
        user: user?.user,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const refreshToken = jwtTokens?.refreshToken;
        const accessToken = data.accessToken;
        setJwtTokens({ refreshToken: refreshToken, accessToken: accessToken });
      });
  }, [expired]);
  useEffect(() => {
    if (tokenVerified === false && user) {
      setExpired(true);
    } else {
      setExpired(false);
    }
  }, [tokenVerified]);
  useEffect(() => {
    fetch("https://cryptochatapi.web.app/authenticateToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
      },
      body: JSON.stringify({ token: jwtTokens?.accessToken, user: user?.user }),
    })
      .then((response) => response.json())
      .then((data) => setTokenVerified(data.verified))
      .catch((error) => {
        setTokenVerified(false);
      });
  });
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home tokenVerified={tokenVerified} setJwtTokens={setJwtTokens} setUser={setUser} user={user} jwtTokens={jwtTokens} setExpired={setExpired} expired={expired} setTokenVerified={setTokenVerified} />} />
          <Route path="/About-Us" element={<About tokenVerified={tokenVerified} setJwtTokens={setJwtTokens} setUser={setUser} user={user} jwtTokens={jwtTokens} setExpired={setExpired} expired={expired} setTokenVerified={setTokenVerified} />} />
          <Route path="/Private-Fiat-Ramp" element={<PrivateFiat tokenVerified={tokenVerified} setJwtTokens={setJwtTokens} setUser={setUser} user={user} jwtTokens={jwtTokens} setExpired={setExpired} expired={expired} setTokenVerified={setTokenVerified} />} />
          <Route path="/Cross-Chain-Bridge" element={<CrossChain tokenVerified={tokenVerified} setJwtTokens={setJwtTokens} setUser={setUser} user={user} jwtTokens={jwtTokens} setExpired={setExpired} expired={expired} setTokenVerified={setTokenVerified} />} />
          <Route path="/EVM-Dex" element={<EVM tokenVerified={tokenVerified} setJwtTokens={setJwtTokens} setUser={setUser} user={user} jwtTokens={jwtTokens} setExpired={setExpired} expired={expired} setTokenVerified={setTokenVerified} />} />
          <Route path="Web3-GPT" element={<Web3GPT tokenVerified={tokenVerified} jwtTokens={jwtTokens} user={user}  />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
