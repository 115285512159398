import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Ledger from "../assets/ledger-banner.webp";
import exodus from "../assets/exodus.jpeg";
import deribit from "../assets/deribit.png";
import gmx from "../assets/gmx-logo.png";
import trezor from "../assets/trezor.png";
import aave from "../assets/aave2.svg";
import { LoginModal } from "./LoginModal";
import { RegisterModal } from "./RegisterModal";
import { TbSwitchHorizontal } from "react-icons/tb";
import { IoWalletSharp } from "react-icons/io5";
import { BsCoin } from "react-icons/bs";
import { RiRobot2Line } from "react-icons/ri";
import { GoSignOut } from "react-icons/go";
import { CiRainbow } from "react-icons/ci";
import { LiaBookSolid } from "react-icons/lia";
import metamask from "../assets/metamask.jpg";
function DesktopButtons({
  jwtTokens,
  setJwtTokens,
  user,
  setUser,
  expired,
  setExpired,
  tokenVerified,
  setTokenVerified,
}) {
  const [listSwap, setListSwap] = useState("EVM Dex");
  const location = useLocation()?.pathname;
  const [dropdown, setDropdown] = useState(false);
  const [edDropdown, setEdDropdown] = useState(false);
  const [futuresDropdown, setFuturesDropdown] = useState(false);
  const [walletDropdown, setWalletDropdown] = useState(false);
  const [switchShow, setSwitchShow] = useState(true);
  const [loginShow, setLoginShow] = useState(false);
  const [currency, setCurrency] = useState("USD");
  console.log(currency);
  const handleWalletClose = (e) => {
    if (e.target.id === "walletCloser") {
      setWalletDropdown(!walletDropdown);
    }
  };
  const handleFuturesClose = (e) => {
    if (e.target.id === "futuresCloser") {
      setFuturesDropdown(!futuresDropdown);
    }
  };
  return (
    <div className="hidden xl:flex flex-col">
      <coingecko-coin-price-marquee-widget
        coin-ids="bitcoin,ethereum,pulsechain,pulsex,boson-protocol,ovr,solana,apecoin,chainlink,wax,airswap,bitxor,gmx,looksrare,ripple,cardano,rarible,aave,tezos"
        currency="usd"
        background-color="#D07FF0"
        locale="en"
      ></coingecko-coin-price-marquee-widget>
      <div className="hidden navbar bg-black bg-opacity-50 font-medium  xl:flex  px-4 py-2 mt-2 mb-4 w-[80%] rounded-xl self-center justify-around">
        <div className="flex flex-col">
          <button
            onClick={() => {
              setDropdown(!dropdown);
            }}
            className={
              dropdown
                ? " text-center text-l text-white transition-all  p-1  w-[4.8rem]  h-16"
                : "hover:font-bold hover-underline-animation hover:scale-110 flex text-center text-l w-20 text-white transition-all active:scale-[90%]  p-1  h-16"
            }
          >
            <div className="flex self-center gap-1">
              <TbSwitchHorizontal className="self-center" />
              Trade{" "}
            </div>
            {dropdown && (
              <div className=" z-50 relative top-[5px] left-[60px]  backdrop-blur flex items-center place-content-center ">
                <div className="modal glass ">
                  <div className="flex flex-col gap-4  p-4">
                    <Link to="/EVM-Dex">
                      <button
                        className="effect-button text-center text-xl text-white transition-all active:scale-[90%]  p-1  w-52 self-center"
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                      >
                        EVM DEX
                      </button>
                    </Link>
                    <Link to="/">
                      <button
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                        className="effect-button text-center text-xl text-white transition-all active:scale-[90%]  p-1   w-52 self-center"
                      >
                        DEX Aggregator
                      </button>
                    </Link>
                    <Link to="/Cross-Chain-Bridge">
                      <button
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                        className="effect-button text-center text-xl text-white transition-all active:scale-[90%]  p-1   w-52 self-center"
                      >
                        Cross Chain Bridge
                      </button>
                    </Link>
                    <Link to="/About-Us">
                      <button
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                        className="effect-button text-center text-xl text-white transition-all active:scale-[90%]  p-1   w-52 self-center"
                      >
                        About Us
                      </button>
                    </Link>
                    <Link to="/Private-Fiat-Ramp">
                      <button
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                        className="effect-button text-center text-xl text-white transition-all active:scale-[90%] active:border-2 p-1   w-52 self-center"
                      >
                        Private Fiat On-Ramp
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </button>
        </div>
        <div className="flex flex-col">
          <button
            onClick={() => {
              setEdDropdown(!edDropdown);
            }}
            className={
              edDropdown
                ? " text-center text-l text-white transition-all  p-1  w-28  h-16"
                : "hover:font-bold hover-underline-animation hover:scale-110 flex text-center text-l w-28 text-white transition-all active:scale-[90%]  p-1  h-16"
            }
          >
            <div className="flex self-center gap-1">
              <LiaBookSolid className="self-center" />
              Education{" "}
            </div>
            {edDropdown && (
              <div className=" z-50 relative top-[5px] left-[60px]  backdrop-blur flex items-center place-content-center ">
                <div className="modal glass ">
                  <div className="flex flex-col gap-4  p-4">
                    <a
                      href="https://oaksoft.medium.com/"
                      className="effect-button text-center text-xl text-white transition-all active:scale-[90%]  p-1   w-52 self-center"
                      target="_blank"
                    >
                      Blog
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=umepbfKp5rI&t=155s"
                      className="effect-button text-center text-xl text-white transition-all active:scale-[90%]  p-1   w-52 self-center"
                      target="_blank"
                    >
                      Blockchain Development
                    </a>
                    <a
                      href="https://uniswap.university/guides"
                      className="effect-button text-center text-xl text-white transition-all active:scale-[90%]  p-1   w-52 self-center"
                      target="_blank"
                    >
                      DeFi University
                    </a>
                  </div>
                </div>
              </div>
            )}
          </button>
        </div>
        <div className="flex flex-col">
          <button
            onClick={() => {
              setWalletDropdown(!walletDropdown);
            }}
            className="hover:font-bold hover-underline-animation hover:scale-110 text-center text-l text-white transition-all active:scale-[90%]  p-1  h-16"
          >
            <div className="flex gap-2">
              <IoWalletSharp className="self-center" />
              Wallets
            </div>
          </button>
          {walletDropdown && (
            <div
              id="walletCloser"
              onClick={handleWalletClose}
              className="hidden lg:flex z-50 absolute backdrop-blur top-0 bottom-0 left-0 flex-col  items-center place-content-center right-0 "
            >
              <div className=" flex   flex-col justify-around h-[95%] rounded-2xl navbar glass py-2 w-[25%]">
                <button
                  id="walletCloser"
                  onClick={handleWalletClose}
                  className=" button-blue-effect  self-center text-center text-l md:text-xl text-white font-bold transition-all active:scale-[90%] active:border-2 p-1  w-28 md:w-32 h-16 "
                >
                  Exit
                </button>
                <div className="self-center">
                  <a
                    href="https://shop.ledger.com/pages/ledger-nano-s-plus/?r=864f0adf98ae"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="rounded-xl w-64 h-36" src={Ledger} />
                  </a>
                </div>
                <div className="self-center">
                  <a
                    href="https://www.exodus.com/download?referral=true&referrerId=H8SWBC"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={exodus} className="rounded-xl w-64 h-36" />
                  </a>
                </div>
                <div className="self-center">
                  <a
                    href="https://trezor.io/?transaction_id=10212df93b18c00a94ffb72d75b4c4&offer_id=133&affiliate_id=33988"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={trezor} className="rounded-xl w-64 h-36" />
                  </a>
                </div>
                <div className="self-center">
                  <a
                    href="https://metamask.io/download/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={metamask} className="rounded-xl w-64 h-36" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <button
            className="hover:font-bold hover-underline-animation2  text-center text-l text-white transition-all p-1   h-16"
            onClick={() => setFuturesDropdown(!futuresDropdown)}
          >
            <div className="flex gap-2">
              {" "}
              <BsCoin className="self-center" /> DeFi Loans & Futures
            </div>
          </button>
          {futuresDropdown && (
            <div
              id="futuresCloser"
              onClick={handleFuturesClose}
              className="hidden lg:flex z-50 absolute backdrop-blur top-0 bottom-0 left-0 flex-col  items-center place-content-center right-0 "
            >
              <div className=" flex navbar glass flex-col justify-around rounded-2xl h-[90%] py-4   w-[60%]">
                <button
                  id="futuresCloser"
                  onClick={handleFuturesClose}
                  className=" button-blue-effect  self-center text-center text-l md:text-xl text-white font-bold transition-all active:scale-[90%] active:border-2 p-1  w-28 md:w-32 h-16 "
                >
                  Exit
                </button>
                <div className="flex flex-col overflow-y-scroll no-scrollbar h-[80%] py-4">
                  <div className="self-center flex my-4 flex-col">
                    <div className="self-center mb-2">
                      <a
                        href="https://bit.ly/CryptoFuturesAndOptions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="w-36 h-36 rounded-xl" src={deribit} />
                      </a>
                    </div>
                    <div className="self-center w-[90%]">
                      <a
                        href="https://bit.ly/CryptoFuturesAndOptions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p className=" text-purple-effect text-white py-4 px-2 text-l tracking-wide">
                          Trade Crypto Derivatives, Options, Perpetuals,
                          Futures, and Spot at Deribit. Deribit is a
                          professional grade cryptocurrency derivatives
                          platform. Our goal was to create an efficient and fair
                          marketplace that could connect traders of all
                          backgrounds and trading styles. High matching engine
                          capacity, low latency, advanced risk management, and
                          high liquidity makes Deribit a unique player in the
                          market.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="self-center flex my-4 flex-col">
                    <div className="self-center mb-2">
                      <a
                        href="https://bit.ly/CryptoFuturesAndOptions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="w-36 h-36 rounded-xl" src={aave} />
                      </a>
                    </div>
                    <div className="self-center w-[90%]">
                      <a
                        href="https://aave.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p className=" text-purple-effect text-white py-4 px-2 text-l tracking-wide">
                          AAVE is a DeFi loans platform, offering a way for
                          people to earn interest by lending their money or
                          borrowing against their own assets. You can deposit
                          your money into AAVE's pool and earn a high interest
                          over time or take out a crypto loan. AAVE provides two
                          options for borrowers: overcollateralized loans, where
                          you borrow money by locking in assets worth more than
                          the loan (more stable) or undercollateralized loans,
                          which are short-term and require less collateral but
                          are executed swiftly.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="self-center flex flex-col">
                    <div className="self-center mb-2 glass rounded-xl p-4">
                      <a
                        href="https://app.gmx.io/#/trade/?ref=quantumx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="w-24 h-24 rounded-xl" src={gmx} />
                      </a>
                    </div>
                    <div className="self-center w-[90%]">
                      <a
                        href="https://app.gmx.io/#/trade/?ref=quantumx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p className=" text-purple-effect text-white py-4 px-2 text-l tracking-wide">
                          GMX is a decentralized perpetual and spot exchange,
                          where you can trade BTC, ETH, and AVAX directly from
                          your metamask wallet. It offers the option to borrow
                          up to 50 times your money to increase your trading
                          power(50X leverage), which is a concept known as
                          “leverage". Trading is supported by unique multi-asset
                          pools that earns liquidity providers fees from market
                          making, swap fees and leverage trading.
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <Link className="self-center" to="/Cross-Chain-Bridge">
            <button className="hover:font-bold hover-underline-animation self-center text-center text-l text-white transition-all p-1   h-16">
              <div className="flex gap-2">
                <CiRainbow className="self-center w-6 h-6" />
                Bridge
              </div>
            </button>
          </Link>
        </div>
        {tokenVerified && (
          <Link to="/Web3-GPT">
            <button className="hover:font-bold hover-underline-animation  text-center text-l text-white transition-all p-1   w-28 md:w-32 h-16">
              <div className="flex gap-2">
                <RiRobot2Line className="self-center" />
                Web3-GPT
              </div>
            </button>
          </Link>
        )}
        {user ? (
          <button
            className="hover:font-bold hover-underline-animation  text-center text-l text-white transition-all p-1  h-16"
            onClick={() => {
              fetch("https://web3stormapi.web.app/signOut");
              setJwtTokens(null);
              setUser(null);
              setExpired(false);
            }}
          >
            <div className="flex gap-2">
              <GoSignOut className="self-center" />
              Sign Out
            </div>
          </button>
        ) : (
          <button
            className="hover:font-bold hover-underline-animation  text-center text-l text-white transition-all p-1   w-28 md:w-32 h-16"
            onClick={() => setLoginShow(!loginShow)}
          >
            <div className="flex gap-2">
              <RiRobot2Line className="self-center" />
              Web3-GPT
            </div>
          </button>
        )}
        {loginShow &&
          (switchShow ? (
            <LoginModal
              setShow={setLoginShow}
              setTokens={setJwtTokens}
              show={loginShow}
              user={user}
              setUser={setUser}
              setSwitchShow={setSwitchShow}
              switchShow={switchShow}
            />
          ) : (
            <RegisterModal
              user={user}
              setUser={setUser}
              setSwitchShow={setSwitchShow}
              switchShow={switchShow}
              setTokens={setJwtTokens}
              setShow={setLoginShow}
              show={loginShow}
            />
          ))}
      </div>
    </div>
  );
}

export default DesktopButtons;
