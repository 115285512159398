import React, { useState, useEffect } from "react";
import Ledger from "../assets/ledger-banner.webp";
import exodus from "../assets/exodus.jpeg";
import ledgerad from "../assets/ledgerad.jpeg";
import trezorad from "../assets/trezor-social.jpeg";
import Agora from "../assets/AgoraDesk.png";
import MobileButtons from "./MobileButtonsCC";
import DesktopButtons from "./DesktopButtons";
import { useBridgeHook } from "./BridgeHook";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
function CrossChain({
  jwtTokens,
  setJwtTokens,
  user,
  setUser,
  expired,
  setExpired,
  tokenVerified,
  setTokenVerified,
}) {
  useBridgeHook();
  const [timeDropdown, setTimeDropdown] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const locale = localStorage.getItem("locale");
  const timezone = localStorage.getItem("timezone");
  const [navbarVisible, setNavbarVisible] = useState(true);
  console.log(navbarVisible);
  const locales = [
    "en",
    "in",
    "de_DE",
    "fr",
    "es",
    "it",
    "pl",
    "sv_SE",
    "tr",
    "ru",
    "br",
    "id",
    "ms_MY",
    "th_TH",
    "vi_VN",
    "ja",
    "kr",
    "zh_CN",
    " zh_TW",
    "ar_AE",
    "he_IL",
    "hu_HU",
    "fa_IR",
  ];
  const timezones = [
    "ETC/UTC",
    "America/New_York",
    "America/Los_Angeles",
    "America/Chicago",
    "America/Juneau",
    "America/Denver",
    "America/Phoenix",
    "America/Anchorage",
    "Pacific/Honolulu",
    "America/Bogota",
    "America/Mexico_City",
    "America/San_Salvador",
    "America/Lima",
    "America/Toronto",
    "America/Caracas",
    "America/Buenos_Aires",
    "America/Santiago",
    "America/Sao_Paulo",
    "Europe/Dublin",
    "Europe/Lisbon",
    "Europe/London",
    "Europe/Reykjavik",
    "Europe/Amsterdam",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Rome",
    "Europe/Paris",
    "Africa/Cairo",
    "Europe/Bucharest",
    "Europe/Istanbul",
    "Asia/Kuwait",
    "Europe/Moscow",
    "Asia/Tehran",
    "Asia/Dubai",
    "Asia/Karachi",
    "Asia/Colombo",
    "Asia/Kathmandu",
    "Asia/Almaty",
    "Asia/Yangon",
    "Asia/Bangkok",
    "Asia/Hong Kong",
    "Asia/Seoul",
    "Asia/Tokyo",
    "Australia/Brisbane",
    "Australia/Adelaide",
    "Australia/Sydney",
    "Pacific/Norfolk",
    "Pacific/Auckland",
  ];
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };
  const handleDropdownClose = (e) => {
    localStorage.setItem("locale", e);
    setDropdown(false);
    window.location.reload();
  };
  const handleTimeDropdown = () => {
    setTimeDropdown(!timeDropdown);
  };
  const handleTimeDropdownClose = (e) => {
    localStorage.setItem("timezone", e);
    setTimeDropdown(false);
    window.location.reload();
  };

  return (
    <>
      <div className="h-[100vh] w-[100vw] flex flex-col page-bg overflow-clip">
        {" "}
        <div className="flex flex-col z-50">
          <DesktopButtons
            tokenVerified={tokenVerified}
            setJwtTokens={setJwtTokens}
            setUser={setUser}
            user={user}
            jwtTokens={jwtTokens}
            setExpired={setExpired}
            expired={expired}
            setTokenVerified={setTokenVerified}
          />
          <MobileButtons
            tokenVerified={tokenVerified}
            setJwtTokens={setJwtTokens}
            setUser={setUser}
            user={user}
            jwtTokens={jwtTokens}
            setExpired={setExpired}
            expired={expired}
            setTokenVerified={setTokenVerified}
            navbarVisible={navbarVisible}
          />
        </div>
        <div className=" z-2 h-full w-full hidden xl:flex overflow-hidden flex-col">
          <div className="h-[90%] w-full my-2 flex">
            {" "}
            <div className="flex flex-col h-[80vh] w-[75%] gap-2  ">
              <div className="h-full overflow-hidden">
                <AdvancedRealTimeChart
                  theme="dark"
                  interval="240"
                  symbol="ETHUSDC"
                  timezone={timezone}
                  locale={locale}
                  autosize
                />
              </div>
              <div className="flex justify-around my-4 gap-2">
            
              <div className="flex gap-2">
                {" "}
                <p className="text-purple-effect rounded-lg  text-white font-bold h-10  p-2">
                  Language
                </p>
                <button
                  onClick={handleDropdown}
                  className="text-blue-effect text-center rounded-lg text-white h-8 mt-1  px-2"
                >
                  {localStorage.getItem("locale")
                    ? localStorage.getItem("locale")
                    : "en"}
                </button>
        

              <div
                id={`${dropdown ? "opendown" : "closeup"}`}
                className="h-16 px-4 self-center rounded-lg text-purple-effect overflow-y-scroll no-scrollbar  flex flex-col"
              >
                {" "}
                {locales.map((l) => {
                  const locale = l;
                  return (
                    <button
                      onClick={(e) => handleDropdownClose(locale)}
                      className="self-center text-white text-blue-effect my-2 px-4"
                    >
                      {l}{" "}
                    </button>
                  );
                })}{" "}
              </div>
            </div>
            
              
              <div className="flex gap-2">
              <p className="rounded-lg text-white font-bold text-purple-effect h-10 p-2">
                  Timezone
                </p>
                <button
                  onClick={handleTimeDropdown}
                  className="text-blue-effect rounded-lg text-center text-white h-10 px-2 "
                >
                  {localStorage.getItem("timezone")
                    ? localStorage.getItem("timezone")
                    : "ETC/UTC"}
                </button>

                <div
                  id={`${timeDropdown ? "opendown" : "closeup"}`}
                  className={` px-4  text-purple-effect overflow-y-scroll no-scrollbar rounded-lg flex flex-col `}
                >
                  {" "}
                  {timezones.map((t) => {
                    const timezone = t;
                    return (
                      <button
                        onClick={(e) => handleTimeDropdownClose(timezone)}
                        className="self-center text-white text-blue-effect my-2 px-4"
                      >
                        {timezone}{" "}
                      </button>
                    );
                  })}{" "}
                </div>
             
            </div>
          </div>
            </div>
          
            <div className="w-[600px] no-scrollbar overflow-hidden h-[700px] " id="debridgeWidget" />
           
          </div>
        </div>
        <div
          onScroll={(e) => {
            if (e.target.scrollTop > 50) {
              setNavbarVisible(false);
            } else {
              setNavbarVisible(true);
            }
          }}
          className={`xl:hidden flex flex-col z-2 ${
            navbarVisible ? "h-[50vh] " : "h-[85vh]"
          } sm:h-[80vh] overflow-y-scroll no-scrollbar overflow-x-hidden`}
        >
          {" "}
          <div className={`self-center h-[700px] w-[300px] ${navbarVisible ? '' : "mt-20"}`}>
            <iframe
              className="h-[100vh] w-[100vw]"
              src="deMobile.html"
            ></iframe>
          </div>
          <div className="flex flex-col mt-2 w-full no-scrollbar h-[590px]">
            <div className="flex self-center gap-2 py-2">
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  {" "}
                  <p className="text-purple-effect rounded-lg self-center text-white font-bold  p-2">
                    Language
                  </p>
                  <button
                    onClick={handleDropdown}
                    className="text-blue-effect text-center rounded-lg text-white h-8 self-center px-2"
                  >
                    {localStorage.getItem("locale")
                      ? localStorage.getItem("locale")
                      : "en"}
                  </button>
                </div>

                <div
                  id={`${dropdown ? "opendown" : "closeup"}`}
                  className="h-16 px-4 self-center mt-2 rounded-lg text-purple-effect overflow-y-scroll no-scrollbar  flex flex-col"
                >
                  {" "}
                  {locales.map((l) => {
                    const locale = l;
                    return (
                      <button
                        onClick={(e) => handleDropdownClose(locale)}
                        className="self-center text-white text-blue-effect my-2 px-4"
                      >
                        {l}{" "}
                      </button>
                    );
                  })}{" "}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <p className="self-center rounded-lg text-white font-bold text-purple-effect p-2">
                    Timezone
                  </p>
                  <button
                    onClick={handleTimeDropdown}
                    className="text-blue-effect rounded-lg text-center text-white h-10 px-2 "
                  >
                    {localStorage.getItem("timezone")
                      ? localStorage.getItem("timezone")
                      : "ETC/UTC"}
                  </button>
                </div>

                <div
                  id={`${timeDropdown ? "opendown" : "closeup"}`}
                  className={` self-center mt-2  text-purple-effect overflow-y-scroll no-scrollbar rounded-lg flex flex-col `}
                >
                  {" "}
                  {timezones.map((t) => {
                    const timezone = t;
                    return (
                      <button
                        onClick={(e) => handleTimeDropdownClose(timezone)}
                        className="self-center px-2 text-white text-blue-effect my-2"
                      >
                        {timezone}{" "}
                      </button>
                    );
                  })}{" "}
                </div>
              </div>
            </div>
            <div className="h-full w-full overflow-y-hidden">
              <AdvancedRealTimeChart
                theme="dark"
                symbol="ETHUSDC"
                timezone={timezone}
                locale={locale}
                interval="240"
                className="self-center"
                height={500}
                width={window.innerWidth - 50}
                adaptive_logo={true}
              />
            </div>
          </div>
         
         
        </div>
      </div>
    </>
  );
}

export default CrossChain;
