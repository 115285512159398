import React, { useState } from "react";
import MobileButtons from "./MobileButtons.jsx";
import DesktopButtons from "./DesktopButtons.jsx";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { CowSwapWidget, TradeType, createCowSwapWidget } from "@cowprotocol/widget-react";
const params = {
  appCode: "Etherstream", // Name of your app (max 50 characters)
  width: "100%", // Width in pixels (or 100% to use all available space)
  height: "400px",
  chainId: 1, // 1 (Mainnet), 100 (Gnosis), 11155111 (Sepolia)
  tokenLists: [
    // All default enabled token lists. Also see https://tokenlists.org
    "https://www.gemini.com/uniswap/manifest.json",
    "https://files.cow.fi/tokens/CowSwap.json",
    "https://files.cow.fi/tokens/CoinGecko.json",
    "https://tokenlist.aave.eth.link",
    "https://gateway.ipfs.io/ipns/tokens.uniswap.org",
  ],
  tradeType: TradeType.SWAP, // TradeType.SWAP, TradeType.LIMIT or TradeType.ADVANCED
  sell: {
    // Sell token. Optionally add amount for sell orders
    asset: "WBTC",
    amount: "1",
  },
  buy: {
    // Buy token. Optionally add amount for buy orders
    asset: "USDC",
    amount: "0",
  },
  enabledTradeTypes: [
    // TradeType.SWAP, TradeType.LIMIT and/or TradeType.ADVANCED
    TradeType.SWAP,
    TradeType.LIMIT,
    TradeType.ADVANCED,
  ],
  theme: {
    // light/dark or provide your own color palette
    baseTheme: "dark",
    primary: "#4e0062",
    paper: "#000a62",
  },
  standaloneMode: true,
  disableToastMessages: false,
  provider: "EIP-1271 Provider",
};

function EVM({
  jwtTokens,
  setJwtTokens,
  user,
  setUser,
  expired,
  setExpired,
  tokenVerified,
  setTokenVerified,
}) {

  return (
    <>
      <div className="h-[100vh] no-scrollbar w-[100vw] z-10 flex flex-col absolute overflow-hidden page-bg">
        {" "}
        <div className="flex  z-50 flex-col place-content-between">
          <DesktopButtons
            tokenVerified={tokenVerified}
            setJwtTokens={setJwtTokens}
            setUser={setUser}
            user={user}
            jwtTokens={jwtTokens}
            setExpired={setExpired}
            expired={expired}
            setTokenVerified={setTokenVerified}
          />
          <MobileButtons
            tokenVerified={tokenVerified}
            setJwtTokens={setJwtTokens}
            setUser={setUser}
            user={user}
            jwtTokens={jwtTokens}
            setExpired={setExpired}
            expired={expired}
            setTokenVerified={setTokenVerified}
          />
        </div>
        <div className="flex justify-center overflow-hidden h-full w-full">
          <div className="overflow-y-scroll no-scrollbar mt-2 w-full">
            <CowSwapWidget params={params} />
          </div>
        </div>
      </div>
    </>
  );
}

export default EVM;
