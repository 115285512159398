import React, { useEffect, useState } from "react";
import cryptoavatar from "../assets/cryptoavatar.jpg";
import {CodeParagraph} from "./Code.jsx";
import { FaRegUser } from "react-icons/fa";
export const ChatMessage = ({ message, user }) => {
  const [chatUser,setChatUser] = useState(user.user);
  useEffect(() => {
    if (chatUser.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      const index = chatUser.indexOf("@");
      if (index !== -1) {
        setChatUser(chatUser.substring(0, index));
      }
    }
  },[])
  if (message.role !== "system") {
    return (
      <>
        <div className="text-glass w-[80%] md:max-w-fit text-white/80 outline-white p-4 my-4 outline-4 self-center ease-in-out rounded-lg">
          <div className="flex flex-col items-center">
            <div className="flex items-center align-center place-content-center text-center font-nunito space-x-2">
              {message.role === "assistant" && (<>
                <img className="rounded" src={cryptoavatar} />
                <div className="font-nunito 2xl:text-3xl text-xl">Assistant</div>
                </>
              )}{message.role === "user" && (<>
              <FaRegUser className="2xl:text-2xl" />
              <div className="font-nunito 2xl:text-3xl text-xl">{chatUser}</div></>)}
              
            </div>
            <div className="w-[100%] text-lg 2xl:text-2xl text-center break-normal">
              <CodeParagraph text={message.content}/>
        </div>
          </div>
        </div>
      </>
    );
  }
};
