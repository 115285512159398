import React from "react";
import DesktopButtons from "./DesktopButtons";
import MobileButtons from "./MobileButtons";
import metamask from "../assets/metamask.jpg";

import quantumlogo from "../assets/quantumlogo.jpg";
import telegramlogo from "../assets/telegram.png";

import quantumxref from "../assets/quantumxref.jpg";

import exodus from "../assets/exodus.jpeg";
import ledger from "../assets/ledger2.webp";
import trezor from "../assets/trezorad2.jpg";
import aibot from "../assets/ai-bot.jpeg";
import tokenexpress from "../assets/tokenexpress.jpg";
function About({
  jwtTokens,
  setJwtTokens,
  user,
  setUser,
  expired,
  setExpired,
  tokenVerified,
  setTokenVerified,
}) {
  return (
    <>
      <div className="h-[100vh] w-[100vw] z-10 flex flex-col absolute page-bg">
        {" "}
        <div className="flex  z-50 flex-col place-content-between">
          <DesktopButtons
            tokenVerified={tokenVerified}
            setJwtTokens={setJwtTokens}
            setUser={setUser}
            user={user}
            jwtTokens={jwtTokens}
            setExpired={setExpired}
            expired={expired}
            setTokenVerified={setTokenVerified}
          />
          <MobileButtons
            tokenVerified={tokenVerified}
            setJwtTokens={setJwtTokens}
            setUser={setUser}
            user={user}
            jwtTokens={jwtTokens}
            setExpired={setExpired}
            expired={expired}
            setTokenVerified={setTokenVerified}
          />
        </div>
        <div className="hidden xl:flex effect-purple px-2 py-10 rounded-xl w-full h-full self-center  justify-center overflow-hidden">
          <p className="w-full flex overflow-y-scroll no-scrollbar flex-col text-white px-3 py-2 text-xl text-glass">
            In the rapidly evolving landscape of cryptocurrency and Blockchain
            technologies, staying informed and connected is paramount. Oaksoft
            Digital is at the forefront of this revolution, offering a dynamic
            Telegram channel and community group designed to keep you updated on
            the latest trends in AI and DeFi. Our focus is on delivering a
            continuous stream of cutting-edge research and news articles,
            ensuring our members are always ahead of the curve.
            <div className="w-[800px] self-center h-[400px]">
              <iframe
                className="glass w-[800px] h-[400px]"
                src="https://widget.0xecho.com?color-theme=dark&desc=&has-h-padding=true&has-v-padding=true&modules=comment%2Clike%2Cdislike&receiver=&target_uri=https%3A%2F%2Fetherstream.live"
                frameborder="0"
              ></iframe>
            </div>
            <p className="">
              🔗 For tailored Web3 consultations, connect with us at{" "}
              <a
                rel="noreferrer"
                href="https://t.me/oaksoftdigital"
                className="text-green-400 font-bold"
                target="_blank"
              >
                {" "}
                t.me/oaksoftdigital.
              </a>
              <br></br>
              🌟 Join our vibrant community at{" "}
              <a
                rel="noreferrer"
                href="https://t.me/oaksoftdao"
                className="text-blue-300 font-bold"
                target="_blank"
              >
                t.me/oaksoftdao
              </a>{" "}
              to dive into discussions on trading, technical analysis,
              zero-knowledge proofs, Networking Events, Ethereum Layer 2 scaling
              solutions, and investment strategies that can enhance your
              portfolio. We're not just a community; we're a breeding ground for
              innovation, welcoming new projects and offering funding
              opportunities to groundbreaking ideas.
            </p>
            <div className="flex self-center mt-2 gap-8">
              <coingecko-coin-converter-widget
                coin-id="bitcoin"
                currency="usd"
                background-color="#41fbab"
                font-color="#050505"
                locale="en"
                width="300"
              ></coingecko-coin-converter-widget>
              <a
                rel="noreferrer"
                href="https://metamask.io/download/"
                target="_blank"
                className="flex self-center flex-col "
              >
                <img
                  id="longrightslide"
                  src={metamask}
                  className="slideleft rounded-xl self-start h-44 w-52 sm:w-72 sm:h-52"
                />
                <button
                  id="longrightslide"
                  className="self-center p-2 text-white mt-2 effect-button"
                >
                  Download Metamask!
                </button>
              </a>
              <a
                href="https://tokensex.link/"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <img className="h-52 w-[350px]" src={tokenexpress} />
              </a>
            </div>
            <br></br>
            <p>
              We are a team of software developers and economists driven by a
              shared vision of creating a more open, private, and secure
              financial ecosystem. Our platforms are designed with privacy and
              decentralization at their core, empowering users to trade securely
              without the need for KYC processes. We emphasize the importance of
              self-custody and the dangers of relying on centralized platforms
              susceptible to hacks.
              <br></br>
              Our services also include an anonymous swap and fiat system,
              supporting thousands of cryptocurrencies and ensuring your trading
              activities remain confidential and efficient across multiple
              blockchain networks.
              <br></br>
              <br></br>
              Unlock the door to a world where Web3, trading, and AI converge,
              offering new definitions of success and opportunity. By joining
              our Telegram group, you become part of an elite community focused
              on sharing insights and strategies to navigate the complexities of
              the digital era. Enjoy a curated feed of articles, blogs and
              research papers that will turn you into an expert in Web3 and
              Artificial Intelligence!
              <br></br>
            </p>
            <br></br>
            <div className="flex flex-row justify-around">
              <a
                rel="noreferrer"
                href="https://www.exodus.com/download/?referral=true&referrerId=H8SWBC"
                target="_blank"
                className="self-center"
              >
                <img
                  src={exodus}
                  className=" w-56 h-44 sm:h-44 sm:w-72 cursor-pointer rounded-xl self-center"
                />
              </a>
              <a
                rel="noreferrer"
                href="https://shop.ledger.com/pages/ledger-nano-s-plus/?r=864f0adf98ae"
                target="_blank"
                className="self-center"
              >
                <img
                  src={ledger}
                  className=" w-72 h-44  cursor-pointer rounded-xl self-center"
                />
              </a>

              <a
                href="https://pionex.com/quantumx"
                rel="noreferrer"
                className="self-center"
                target="_blank"
              >
                <img
                  src={quantumxref}
                  className="w-56 cursor-pointer h-64 rounded-xl self-center"
                />
              </a>
            </div>
            <br></br>
            <br></br>
            <p className="text-center font-bold text-xl">Why Join Us?</p>
            Exclusive Insights: Gain access to advanced crypto analysis and
            strategies.
            <br></br>
            Community Support: Engage with a network of peers eager to share
            their knowledge and support your growth.
            <br></br>
            Exclusive Content: Enjoy access to specially curated blogs and
            articles.
            <br></br>
            Real-time Updates: Keep up with the market through real-time updates
            and alerts.
            <br></br>
            Growth Opportunities: No matter your experience level, our community
            offers resources to fuel your journey in the crypto world.
            <br></br>
            Don't miss out on this opportunity to enhance your understanding and
            success in the crypto and AI space. Click the links and take your
            first step towards achieving your crypto goals.
            <p className="text-center font-bold">👇 Join Us Now 🌐✨ </p>
            <div className="self-center w-full flex flex-col sm:flex-row gap-5 sm:gap-0 justify-center my-2">
              <a
                href="https://t.me/oaksoftdigital"
                className="self-center sm:mr-10"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className=" self-center h-40 w-40 rounded-full hover:border-4"
                  src={telegramlogo}
                />
              </a>
              <a
                rel="noreferrer"
                href="https://calendly.com/etherstream"
                className="self-center"
                target="_blank"
              >
                <img
                  className=" self-center h-40 w-40 rounded-full hover:border-4"
                  src={quantumlogo}
                />
              </a>
            </div>
          </p>
        </div>
        <div className="xl:hidden effect-purple flex flex-col overflow-y-scroll no-scrollbar mt-2 h-[55vh] md:h-[75vh] rounded-xl px-2  py-8 ">
          <p className="w-full flex flex-col text-white px-3 py-2 text-xl text-glass">
            In the rapidly evolving landscape of cryptocurrency and Blockchain
            technologies, staying informed and connected is paramount. Oaksoft
            Digital is at the forefront of this revolution, offering a dynamic
            Telegram channel and community group designed to keep you updated on
            the latest trends in AI and DeFi. Our focus is on delivering a
            continuous stream of cutting-edge research and news articles,
            ensuring our members are always ahead of the curve.
            <div className="w-[280px] sm:w-[500px] my-4 self-center sm:h-[420px] h-[400px]">
              <iframe
                className="glass  w-[280px] sm:w-[500px] sm:h-[420px] h-[400px]"
                src="https://widget.0xecho.com?color-theme=dark&desc=&has-h-padding=true&has-v-padding=true&modules=comment%2Clike%2Cdislike&receiver=&target_uri=https%3A%2F%2Fetherstream.live"
                frameborder="0"
              ></iframe>
            </div>
            <p className="">
              🔗 For tailored Web3 consultations, connect with us at{" "}
              <a
                rel="noreferrer"
                href="https://t.me/oaksoftdigital"
                className="text-green-400 font-bold"
                target="_blank"
              >
                {" "}
                t.me/oaksoftdigital.
              </a>
              <br></br>
              🌟 Join our vibrant community at{" "}
              <a
                rel="noreferrer"
                href="https://t.me/oaksoftdao"
                className="text-blue-300 font-bold"
                target="_blank"
              >
                t.me/oaksoftdao
              </a>{" "}
              to dive into discussions on trading, technical analysis,
              zero-knowledge proofs, Networking Events, Ethereum Layer 2 scaling
              solutions, and investment strategies that can enhance your
              portfolio. We're not just a community; we're a breeding ground for
              innovation, welcoming new projects and offering funding
              opportunities to groundbreaking ideas.
            </p>
            <div className="flex flex-col lg:flex-row self-center mt-2 gap-8"> 
            <div className="self-center lg:self-auto ">
              <coingecko-coin-converter-widget
                coin-id="bitcoin"
                currency="usd"
                background-color="#41fbab"
                font-color="#050505"
                locale="en"
                width="200"
                
              ></coingecko-coin-converter-widget>
              </div>
               <a rel="noreferrer"
              href="https://metamask.io/download/"
              target="_blank"
              className="flex flex-col "
            >
              <img
                id=""
                src={metamask}
                className=" rounded-xl h-44 w-52 sm:w-72 sm:h-52"
              />
              <button
                id=""
                className="self-center p-2 text-white mt-2 effect-button"
              >
                Download Metamask!
              </button>
            </a>
              <a
                href="https://tokensex.link/"
                target="_blank"
                rel="noreferrer"
                className="w-[300px] lg:self-auto self-center "
              >
                <img className="h-44 w-[300px]" src={tokenexpress} />
              </a>
           
             
             </div>
            <br></br>
            <p>
              We are a team of software developers and economists driven by a
              shared vision of creating a more open, private, and secure
              financial ecosystem. Our platforms are designed with privacy and
              decentralization at their core, empowering users to trade securely
              without the need for KYC processes. We emphasize the importance of
              self-custody and the dangers of relying on centralized platforms
              susceptible to hacks.
              <br></br>
              Our services also include an anonymous swap and fiat system,
              supporting thousands of cryptocurrencies and ensuring your trading
              activities remain confidential and efficient across multiple
              blockchain networks.
              <br></br>
              <br></br>
              Unlock the door to a world where Web3, trading, and AI converge,
              offering new definitions of success and opportunity. By joining
              our Telegram group, you become part of an elite community focused
              on sharing insights and strategies to navigate the complexities of
              the digital era. Enjoy a curated feed of articles, blogs and
              research papers that will turn you into an expert in Web3 and
              Artificial Intelligence!
              <br></br>
            </p>
            <br></br>
            <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row justify-around">
              <a
                rel="noreferrer"
                href="https://www.exodus.com/download/?referral=true&referrerId=H8SWBC"
                target="_blank"
                className="self-center"
              >
                <img
                  src={exodus}
                  className=" w-56 h-44 sm:h-52 sm:w-72 cursor-pointer rounded-xl self-center"
                />
              </a>
              <a
                href="https://pionex.com/quantumx"
                rel="noreferrer"
                className="self-center"
                target="_blank"
              >
                <img
                  src={quantumxref}
                  className="w-56 cursor-pointer h-64 rounded-xl self-center"
                />
              </a>
            </div>
            <br></br>
            <br></br>
            <p className="text-center font-bold text-xl">Why Join Us?</p>
            Exclusive Insights: Gain access to advanced crypto analysis and
            strategies.
            <br></br>
            Community Support: Engage with a network of peers eager to share
            their knowledge and support your growth.
            <br></br>
            Exclusive Content: Enjoy access to specially curated blogs and
            articles.
            <br></br>
            Real-time Updates: Keep up with the market through real-time updates
            and alerts.
            <br></br>
            Growth Opportunities: No matter your experience level, our community
            offers resources to fuel your journey in the crypto world.
            <br></br>
            Don't miss out on this opportunity to enhance your understanding and
            success in the crypto and AI space. Click the links and take your
            first step towards achieving your crypto goals.
            <p className="text-center font-bold">👇 Join Us Now 🌐✨ </p>
            <div className="self-center w-full flex flex-col sm:flex-row gap-5 sm:gap-0 justify-center my-2">
              <a
                href="https://t.me/oaksoftdigital"
                className="self-center sm:mr-10"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className=" self-center h-40 w-40 rounded-full hover:border-4"
                  src={telegramlogo}
                />
              </a>
              <a
                rel="noreferrer"
                href="https://calendly.com/etherstream"
                className="self-center"
                target="_blank"
              >
                <img
                  className=" self-center h-40 w-40 rounded-full hover:border-4"
                  src={quantumlogo}
                />
              </a>
            </div>
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
